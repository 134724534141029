<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12">
        <table
          class="
            table table-bordered table-striped table-hover table-sm
            text-sm
          "
        >
          <thead class="bg-gray text-center">
            <tr>
              <th v-show="dato.material_inventarios == 1">
                # Pedido Compra de Material
              </th>
              <th v-show="dato.material_inventarios == 1">
                Pos. en el Pedido
              </th>
              <th class="text-nowrap">Descripción del material o equipos</th>
              <th>Largo x Ancho x Alto (MTS)</th>
              <th>Peso(Kg)</th>
              <th>Valor del Material</th>
              <th>Moneda Área</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="detCarga in dato.det_cargas" :key="detCarga.id">
              <td class="text-center" v-if="dato.material_inventarios == 1">
                {{ detCarga.no_pedido_compra }}
              </td>
              <td class="text-center" v-if="dato.material_inventarios == 1">
                {{ detCarga.pos_pedido }}
              </td>
              <td class="text-center">
                {{ detCarga.descripcion_material }}
              </td>
              <td class="text-center">
                {{ detCarga.medidas }}
              </td>
              <td class="text-center">
                {{ detCarga.peso }}
              </td>
              <td class="text-center">
                {{ detCarga.valor }}
              </td>
              <td class="text-center">
                {{ detCarga.tipoMoneda }}
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  data-toggle="modal"
                  data-target="#modal-form-tipo-servicio"
                  style="cursor: pointer"
                  @click="$parent.abrirModal('Editar', dato.id)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CsSolicitudesNacionalesDetCarga",
  components: {},
  props: {
    indice: Number, // Definir la prop "indice"
    dato: Object, // Definir la prop "dato" u otro tipo de dato según corresponda
  },

  data() {
    return {};
  },

  validations() {},

  methods: {},

  async mounted() {},
};
</script>
